import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#1D1D1B"
      fillRule="evenodd"
      d="M11.743 14.328V10.4h11.184c.09.577.136 1.16.136 1.744a11.008 11.008 0 0 1-3.212 8.12 10.976 10.976 0 0 1-8.108 3.216 10.976 10.976 0 0 1-8.108-3.217 11.008 11.008 0 0 1-3.212-8.119A11 11 0 0 1 3.63 4.017 10.968 10.968 0 0 1 11.743.8a11.287 11.287 0 0 1 8.1 3.2l-3.067 3.136a6.634 6.634 0 0 0-5.009-2.184c-3.914 0-6.75 3.056-6.75 7.2s2.836 7.2 6.75 7.2a6.584 6.584 0 0 0 6.487-5.008l-6.51-.016z"
    />
  </svg>
);
