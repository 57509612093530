import { createUseStyles } from 'react-jss';

export default createUseStyles(({ components, minWidth, maxWidth }) => {
  const { header, curtain } = components;

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      minWidth,
      minHeight: 450,
    },
    content: {
      width: '100%',
      height: 1,
      minHeight: `calc(100% - ${curtain.height}px)`,
      maxWidth: ({ isIndexPage }) => (isIndexPage ? 'none' : maxWidth),
      background: '#fff',
      paddingTop: ({ isIndexPage }) => (isIndexPage ? curtain.height : curtain.height + header.height),
      filter: ({ showIntro }) => (showIntro ? 'blur(7px)' : 'none'),
      boxSizing: 'border-box',
      '@media (max-width: 799px)': {
        paddingTop: () => curtain.height,
      },
      '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
        opacity: ({ showIntro }) => (showIntro ? 0.2 : 1),
      },
    },
  };
});
