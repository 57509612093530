export default ({ colors, components, fonts }) => {
  const { background } = components.header;

  return {
    button: {
      display: ({ to }) => (to ? 'flex' : 'none'),
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      outline: 'none',
      ...fonts.subtitleMedium,
      background,
      border: 'none',
      borderTop: [1, 'solid'],
      borderBottom: [1, 'solid'],
      borderColor: colors.black,
      color: colors.black,
      fontSize: 22,
      '&:first-child': {
        borderTop: [2, 'solid'],
      },
      '&:last-child': {
        borderBottom: [2, 'solid'],
      },
      '&:disabled': {
        color: colors.button.active,
        borderColor: colors.black,
      },
    },
  };
};
