import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23">
    <path
      fill="#1D1D1B"
      fillRule="evenodd"
      d="M10.816 9.6h.304L17.91.448h3.915v21.8H17.47V6.984h-.088a9.301 9.301 0 0 1-1.214 2.448l-3.052 4.096h-4.33L5.736 9.432A9.301 9.301 0 0 1 4.52 6.984h-.087v15.264H.064V.448h3.93L10.816 9.6z"
    />
  </svg>
);
