/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { useMobileDetect } from '@/utils/hooks';

import { containerVariants, buttonVariants } from './utils';
import MenuButton from './MenuButton';

const Curtain = ({
  bottomPosition,
  letterList,
  isShowMenuButton,
  isOpenMobileMenu,
  handleMenuButton,
  classes,
  onClick,
  ...rest
}) => {
  const mobile = useMobileDetect();
  const handleClick = useCallback(() => {
    if (!mobile) {
      onClick();
    }
  }, [mobile, onClick]);

  return (
    <motion.div className={classes.animateContainer} variants={containerVariants} {...rest}>
      <button
        type="button"
        aria-label="Home Page"
        className={classes.contentContainer}
        onClick={handleClick}
        onKeyPress={handleClick}
      >
        {letterList.map((Letter, i) => (
          <div key={i} className={classes.letterContainer}>
            <Letter />
          </div>
        ))}
      </button>
      <motion.button
        aria-label="Menu"
        className={classes.menuButton}
        onClick={handleMenuButton}
        variants={buttonVariants}
        animate={isShowMenuButton && mobile ? 'enter' : 'exit'}
        initial="exit"
      >
        <MenuButton open={isOpenMobileMenu} />
      </motion.button>
    </motion.div>
  );
};

Curtain.defaultProps = {
  bottomPosition: false,
  isShowMenuButton: false,
  isOpenMobileMenu: false,
  handleMenuButton: () => null,
};

Curtain.propTypes = {
  bottomPosition: PropTypes.bool,
  isShowMenuButton: PropTypes.bool,
  isOpenMobileMenu: PropTypes.bool,
  handleMenuButton: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  letterList: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Curtain;
