export default ({ components, minWidth, spacing }) => {
  const { height, background, maxWidth } = components.curtain;

  return {
    animateContainer: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      background,
      width: '100%',
      height: '50%',
      minHeight: height,
      minWidth,
      zIndex: 100,
      boxShadow: '0 0 50px 1px rgba(0,0,0,0.2)',
      alignItems: ({ bottomPosition }) => (bottomPosition ? 'flex-start' : 'flex-end'),
      top: ({ bottomPosition }) => (bottomPosition ? 'auto' : 0),
      bottom: ({ bottomPosition }) => (bottomPosition ? 0 : 'auto'),
      left: 0,
    },
    contentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      height,
      width: '100%',
      maxWidth,
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      outline: 'none',
    },
    letterContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height,
      width: spacing * 3,
      margin: [0, spacing * 2],
    },
    menuButton: {
      display: ({ bottomPosition }) => (bottomPosition ? 'none' : 'flex'),
      alignItems: 'center',
      justifyContent: 'center',
      height,
      width: 0,
      margin: 0,
      padding: 0,
      border: 'none',
      outline: 'none',
      background: 'none',
      overflow: 'hidden',
    },
  };
};
