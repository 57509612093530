import React from 'react';
import Player from 'react-player';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Close from './Close';
import { buttonVariants } from './utils';

const Intro = ({ classes, toHideIntro, url, ...rest }) => (
  <div className={classes.container}>
    <div className={classes.videoContainer}>
      <div className={classes.wrapper}>
        <Player url={url} playing controls width="100%" height="100%" {...rest} />
        <motion.button
          type="button"
          onClick={toHideIntro}
          className={classes.button}
          variants={buttonVariants}
          animate="show"
          initial="hidden"
        >
          <Close />
        </motion.button>
      </div>
    </div>
  </div>
);

Intro.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  toHideIntro: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default Intro;
