import React from 'react';
import PropTypes from 'prop-types';

import BackIcon from '@/icons/BackIcon';

import ItemMenu from './ItemMenu';

const Header = ({ menuItems, classes, currentPath, redirectTo }) => (
  <div className={classes.wrapper}>
    <div className={classes.container}>
      <button type="button" aria-label="Home page" className={classes.backButton} onClick={() => redirectTo('/')}>
        <BackIcon />
      </button>
      {menuItems.slice(1).map(({ name, to }, index) => (
        <ItemMenu
          key={to || index}
          name={name}
          to={to}
          disabled={to === currentPath || (to === '/augenmobil/news/' && currentPath.includes('/augenmobil/news/'))}
          redirectTo={redirectTo}
        />
      ))}
    </div>
  </div>
);

Header.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentPath: PropTypes.string.isRequired,
  redirectTo: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Header;
