export default ({ components, colors }) => {
  const { curtain, header } = components;
  const { primary, hover, click } = colors.link;

  return {
    container: {
      position: 'fixed',
      top: curtain.height + header.height,
      left: 0,
      right: 0,
      overflow: 'hidden',
      zIndex: 12,
      height: `calc(100vh - ${2 * curtain.height}px - ${header.height}px)`,
      '@media (max-width: 799px)': {
        top: curtain.height,
        height: `calc(100vh - ${2 * curtain.height}px)`,
      },
    },
    videoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      zIndex: 1001,
    },
    wrapper: {
      display: 'flex',
      position: 'relative',
      background: '#000',
      maxWidth: 'calc(100vw - 100px)',
      '@media (orientation: landscape)': {
        height: `calc(100vh - ${2 * curtain.height}px - ${header.height}px - 10vh)`,
        width: `calc((100vh - ${2 * curtain.height}px - ${header.height}px - 10vh) * 16 / 9)`,
      },
      '@media (orientation: portrait)': {
        width: '100vw',
        height: 'calc(100vw * 9 / 16)',
      },
      '@media (orientation: landscape) and (max-height: 400px) and (max-width: 799px)': {
        height: `calc(100vh - ${2 * curtain.height}px)`,
        width: `calc((100vh - ${2 * curtain.height}px) * 16 / 9)`,
      },
    },
    button: {
      position: 'absolute',
      left: '100%',
      height: 36,
      width: 36,
      minWidth: 36,
      background: 'none',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      margin: [0, 5],
      padding: 0,
      '& *': {
        color: primary,
        transition: 'all .3s',
      },
      '&:hover': {
        '& *': {
          color: hover,
        },
      },
      '&:active': {
        '& *': {
          color: click,
        },
      },
    },
  };
};
