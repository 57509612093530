import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { upVariants, downVariants } from './utils';

const MenuButton = ({ open, classes }) => (
  <div className={classes.container}>
    <motion.div className={classes.line} variants={upVariants} animate={open ? 'openUp' : 'closeUp'} initial="closeUp" />
    <motion.div className={classes.line} variants={downVariants} animate={open ? 'openDown' : 'closeDown'} initial="closeDown" />
  </div>
);

MenuButton.defaultProps = {
  open: false,
};

MenuButton.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default MenuButton;
