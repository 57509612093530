import withStyles from 'react-jss';

const styles = ({ fonts }) => ({
  '@global': {
    'html, body, #___gatsby, #gatsby-focus-wrapper': {
      height: '100%',
      width: '100%',
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
    },
    html: {
      overflowY: 'auto',
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
      '&::-webkit-scrollbar': {
        width: '0 !important',
      },
    },
    body: {
      overflowScrolling: 'touch',
      '-webkit-overflow-scrolling': 'touch',
      fontSmoothing: 'antialiased',
      '-webkit-font-smoothing': 'antialiased',
      '-webkit-tap-highlight-color': 'rgba(255, 255, 255, 0)',
      ...fonts.regular,
      background: '#fff',
    },
    'input[type=text], textarea': {
      appearance: 'none',
      '-webkit-appearance': 'none',
    },
    button: {
      userSelect: 'none',
      appearance: 'none',
      '-webkit-appearance': 'none',
    },
  },
});

export default withStyles(styles)(() => null);
