const black = '#000';
const norm = 'normal';

const title = {
  fontFamily: 'Work Sans',
  fontWeight: 400,
  fontSize: 26,
  lineHeight: 1.29,
  letterSpacing: norm,
  color: black,
  margin: 0,
  '@media (max-width: 799px)': {
    fontSize: 17,
    lineHeight: 1.24,
  },
};

const subtitle = {
  fontFamily: 'Work Sans',
  fontWeight: 400,
  fontSize: 16,
  lineHeight: 1.25,
  letterSpacing: norm,
  color: black,
  margin: 0,
  '@media (max-width: 799px)': {
    lineHeight: 1.19,
    letterSpacing: '0.3px',
  },
};

const regular = {
  fontFamily: 'Work Sans',
  fontWeight: 400,
  fontSize: 12,
  lineHeight: 1.33,
  letterSpacing: norm,
  color: black,
  margin: 0,
  '@media (max-width: 799px)': {
    lineHeight: 1.5,
    letterSpacing: '-0.2px',
  },
};

module.exports = {
  light: {
    fontFamily: 'Work Sans',
    fontWeight: 300,
    fontSize: 43,
    lineHeight: norm,
    letterSpacing: norm,
    color: black,
    margin: 0,
    '@media (max-width: 799px)': {
      fontSize: 25.8,
      letterSpacing: norm,
    },
  },
  title,
  titleBold: {
    ...title,
    fontWeight: 600,
  },
  subtitle,
  subtitleMedium: {
    ...subtitle,
    fontWeight: 500,
  },
  subtitleBold: {
    ...subtitle,
    fontWeight: 600,
  },
  subtitleSmall: {
    ...subtitle,
    fontSize: 14,
    lineHeight: 1.36,
    '@media (max-width: 799px)': {
      ...subtitle['@media (max-width: 799px)'],
      lineHeight: 1.36,
    },
  },
  regular,
  regularBold: {
    ...regular,
    fontWeight: 600,
  },
  smallText: {
    fontFamily: 'Work Sans',
    fontWeight: 600,
    fontSize: 11,
    lineHeight: 1.17,
    letterSpacing: norm,
    color: black,
    margin: 0,
  },
};
