export default ({ spacing }) => ({
  container: {
    position: 'relative',
    width: '10vw',
    height: spacing * 3,
  },

  line: {
    position: 'absolute',
    top: 0,
    left: '2vw',
    width: spacing * 3,
    height: 2,
    background: '#000',
  },
});
