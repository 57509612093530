import theme from '@/theme';

const { height } = theme.components.curtain;

export const containerVariants = {
  openMenu: {
    top: height,
    bottom: height,
    height: 'auto',
    transition: {
      duration: 0,
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  closeMenu: {
    top: 0,
    bottom: 'auto',
    height: 0,
    transition: { duration: 0 },
  },
};
