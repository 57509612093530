import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      appJson(menu: { id: { eq: "menu" } }) {
        menu {
          augenmobil {
            name
            to
          }
          praxis {
            name
            to
          }
        }
      }
    }
  `);

  return data.appJson.menu;
};
