import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
    <path
      fill="#1D1D1B"
      fillRule="nonzero"
      d="M.064.448h14.795c3.922 0 6.534 2.616 6.534 6.536a4.995 4.995 0 0 1-1.957 4.104 4.977 4.977 0 0 1 2.828 4.616c0 3.928-2.612 6.544-6.535 6.544H.064V.448zm14.795 8.936a2.051 2.051 0 0 0 2.18-2.184v-.656a2.058 2.058 0 0 0-2.18-2.176H4.418v5.008l10.44.008zm.87 8.936a2.051 2.051 0 0 0 2.181-2.176v-.656a2.058 2.058 0 0 0-2.18-2.176H4.417v5.008h11.311z"
    />
  </svg>
);
