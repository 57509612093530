import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const ItemMenu = ({ to, redirectTo, classes, name, disabled }) => {
  const handleRedirect = useCallback(() => {
    redirectTo(to);
  }, [redirectTo, to]);

  return (
    <button type="button" onClick={handleRedirect} className={classes.button} disabled={disabled}>
      {name}
    </button>
  );
};

ItemMenu.propTypes = {
  to: PropTypes.string.isRequired,
  redirectTo: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ItemMenu;
