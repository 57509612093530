const fonts = require('./fonts');

module.exports = {
  maxWidth: 1024,
  minWidth: 350,
  components: {
    header: {
      height: 40,
      itemWidth: 143,
      background: '#fff',
    },
    curtain: {
      height: 55,
      background: '#fff',
      maxWidth: 1024,
    },
    dropDown: {
      height: {
        desktop: 43,
        mobile: 63,
      },
      background: '#fff',
    },
  },
  spacing: 8,
  text: {
    menu: {
      ...fonts.subtitleSmall,
      color: '#676767',
    },
    sizes: {
      desktop: 25.6,
      mobile: 16.6,
    },
    lineHeight: {
      desktop: 1.31,
      mobile: 1.2,
    },
  },
  colors: {
    black: '#000',
    white: '#fff',
    gray: '#aaa8a8',
    grey86: '#868686',
    blue: '#3a00ff',
    red: '#f00',
    active: '#3a00ff',
    button: {
      primary: '#676767',
      active: '#3a00ff',
    },
    link: {
      primary: '#3a00ff',
      hover: 'rgba(58,0,255,0.5)',
      click: '#2601a3',
    },
    text: {
      mobile: '#757575',
      desktop: '#757575',
    },
  },
  fonts,
};
