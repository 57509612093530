export default ({ colors, text, components }) => {
  const { primary, active } = colors.button;
  const { hover } = colors.link;
  const { menu } = text;
  const { height, background, itemWidth } = components.header;

  const shadow = [[0, 2, 23, 0, 'rgba(204, 204, 204, 0.5)']];
  const activeShadow = [[0, 2, 23, 0, 'rgba(204, 204, 204, 0.5)']];

  return {
    button: {
      display: ({ to }) => (to ? 'flex' : 'none'),
      alignItems: 'center',
      justifyContent: 'center',
      width: itemWidth,
      height,
      boxShadow: shadow,
      border: 'none',
      outline: 'none',
      ...menu,
      color: primary,
      transitionProperty: 'box-shadow color',
      transitionDuration: '0.5s',
      transitionTimingFunction: 'ease-in-out',
      '@media (hover)': {
        '&:hover:enabled': {
          color: hover,
          boxShadow: activeShadow,
        },
      },
      background,
      zIndex: 10,
      cursor: 'pointer',
      '&:disabled': {
        color: active,
        boxShadow: activeShadow,
        cursor: 'not-allowed',
      },
    },
  };
};
