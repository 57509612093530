import React, { useState, useEffect, useCallback, createContext, useMemo } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import A from '@/icons/A';
import B from '@/icons/B';
import E from '@/icons/E';
import G from '@/icons/G';
import I from '@/icons/I';
import L from '@/icons/L';
import M from '@/icons/M';
import N from '@/icons/N';
import O from '@/icons/O';
import U from '@/icons/U';
import Curtain from '@/components/Curtain';
import Header from '@/components/Header';
import MobileMenu from '@/components/MobileMenu';
import Intro from '@/components/Intro';
import useMenuContent from '@/utils/useMenuContent';

import useStyles from './styles';

export const LayoutContext = createContext();

const Layout = ({ children, location }) => {
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');
  const [completedPose, setCompletedPose] = useState(false);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [showIntro, setShowIntro] = useState();
  const menu = useMenuContent();
  const classes = useStyles({ isIndexPage: pathname === '/', showIntro });

  const menuItems = useMemo(() => {
    if (pathname.includes('augenmobil')) return menu.augenmobil;
    if (pathname.includes('praxis')) return menu.praxis;
    return menu.augenmobil;
  }, [menu.augenmobil, menu.praxis, pathname]);

  useEffect(() => {
    setOpen(true);
    setShowIntro();
  }, [pathname]);

  useEffect(() => {
    if (!open && redirectTo && completedPose) navigate(redirectTo);
  }, [open, redirectTo, completedPose]);

  const handleRedirect = useCallback(
    (path) => {
      setRedirectTo(path);
      setOpen(false);
      setCompletedPose(false);
      setIsOpenMobileMenu(false);
    },
    [setRedirectTo, setOpen, setCompletedPose]
  );
  const redirectToHome = useCallback(() => {
    if (pathname.includes('augenmobil') && pathname !== '/augenmobil/') handleRedirect('/augenmobil/');
    if (pathname.includes('praxis') && pathname !== '/praxis/') handleRedirect('/praxis/');
  }, [pathname, handleRedirect]);
  const closeMenu = useCallback(() => setIsOpenMobileMenu(!isOpenMobileMenu), [isOpenMobileMenu]);
  const handleCompletePose = useCallback((pose) => setCompletedPose(pose), []);
  const toHideIntro = useCallback(() => setShowIntro(), []);
  const contextValue = useMemo(() => ({ handleRedirect, showIntro, setShowIntro }), [handleRedirect, showIntro]);

  return (
    <LayoutContext.Provider value={contextValue}>
      <>
        <div className={classes.container}>
          <Curtain
            letterList={[A, U, G, E, N]}
            isShowMenuButton={open && completedPose === 'open' && pathname !== '/'}
            isOpenMobileMenu={isOpenMobileMenu}
            handleMenuButton={closeMenu}
            onClick={redirectToHome}
            animate={open ? 'open' : 'close'}
            onAnimationComplete={handleCompletePose}
            initial="close"
          />
          <MobileMenu
            isOpen={open && isOpenMobileMenu}
            menuItems={menuItems}
            redirectTo={handleRedirect}
            currentPath={pathname}
            toCloseMenu={closeMenu}
          />
          <Header menuItems={menuItems} redirectTo={handleRedirect} currentPath={pathname} />
          <div className={classes.content}>{children}</div>
          <Curtain
            letterList={[M, O, B, I, L]}
            onClick={redirectToHome}
            animate={open ? 'open' : 'close'}
            initial="close"
            bottomPosition
          />
        </div>
        {showIntro && <Intro url={showIntro} toHideIntro={toHideIntro} />}
      </>
    </LayoutContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Layout;
