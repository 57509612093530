exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-augenmobil-js": () => import("./../../../src/pages/augenmobil.js" /* webpackChunkName: "component---src-pages-augenmobil-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-praxis-js": () => import("./../../../src/pages/praxis.js" /* webpackChunkName: "component---src-pages-praxis-js" */),
  "component---src-templates-contacts-index-js": () => import("./../../../src/templates/Contacts/index.js" /* webpackChunkName: "component---src-templates-contacts-index-js" */),
  "component---src-templates-faq-index-js": () => import("./../../../src/templates/Faq/index.js" /* webpackChunkName: "component---src-templates-faq-index-js" */),
  "component---src-templates-press-index-js": () => import("./../../../src/templates/Press/index.js" /* webpackChunkName: "component---src-templates-press-index-js" */),
  "component---src-templates-press-item-index-js": () => import("./../../../src/templates/Press/Item/index.js" /* webpackChunkName: "component---src-templates-press-item-index-js" */),
  "component---src-templates-references-index-js": () => import("./../../../src/templates/References/index.js" /* webpackChunkName: "component---src-templates-references-index-js" */),
  "component---src-templates-services-index-js": () => import("./../../../src/templates/Services/index.js" /* webpackChunkName: "component---src-templates-services-index-js" */),
  "component---src-templates-team-index-js": () => import("./../../../src/templates/Team/index.js" /* webpackChunkName: "component---src-templates-team-index-js" */)
}

