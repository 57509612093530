export default {
  container: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    bottom: 0,
    height: 0,
    flexDirection: 'column',
    width: '100vw',
    zIndex: 1000,
    overflow: 'hidden',
    '@media (min-width: 800px)': {
      display: 'none',
    },
  },
};
