import { components } from '@/theme';

const { height } = components.curtain;
const ease = [0.85, 0, 0.15, 1];
const duration = 0.75;

export const containerVariants = {
  close: {
    height: '50%',
    transition: { ease, duration },
  },
  open: {
    height,
    transition: { ease, duration },
  },
};

export const buttonVariants = {
  enter: {
    width: 80,
    transition: { ease, duration: 0.3 },
  },
  exit: {
    width: 0,
    marginRight: 0,
    transition: { ease, duration: 0.1 },
  },
};
