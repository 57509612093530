const time1 = 0.1;
const time2 = 0.4;
const time3 = 0.25;
const time4 = 0.25;
const ease = 'easeOut';

export const upVariants = {
  openUp: {
    top: 11,
    rotate: -45,
    transition: {
      top: {
        duration: time1,
        ease,
      },
      rotate: {
        duration: time2,
        delay: time1,
        ease,
      },
    },
  },
  closeUp: {
    top: 18,
    rotate: 0,
    transition: {
      top: {
        duration: time4,
        delay: time3,
        ease,
      },
      rotate: {
        duration: time3,
        ease,
      },
    },
  },
};

export const downVariants = {
  openDown: {
    top: 11,
    rotate: 45,
    transition: {
      top: {
        duration: time1,
        ease,
      },
      rotate: {
        duration: time2,
        delay: time1,
        ease,
      },
    },
  },
  closeDown: {
    top: 4,
    rotate: 0,
    transition: {
      top: {
        duration: time4,
        delay: time3,
        ease,
      },
      rotate: {
        duration: time3,
        ease,
      },
    },
  },
};
