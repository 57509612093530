import React, { Component } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { itemVariants } from './utils';

class ItemMenu extends Component {
  handleRedirect = () => {
    const { to, redirectTo, toCloseMenu, disabled } = this.props;

    if (disabled) {
      toCloseMenu();
      return;
    }

    redirectTo(to);
  };

  render() {
    const { classes, name, disabled } = this.props;

    return (
      <motion.button
        type="button"
        onClick={this.handleRedirect}
        className={classes.button}
        disabled={disabled}
        variants={itemVariants}
      >
        {name}
      </motion.button>
    );
  }
}

ItemMenu.propTypes = {
  to: PropTypes.string.isRequired,
  redirectTo: PropTypes.func.isRequired,
  toCloseMenu: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ItemMenu;
