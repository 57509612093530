import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23">
    <path
      fill="#1D1D1B"
      fillRule="evenodd"
      d="M15.298 18.32a2.083 2.083 0 0 0 2.173-2.224V.448h4.354v15.256c0 3.928-2.613 6.544-6.527 6.544H6.591c-3.915 0-6.527-2.616-6.527-6.544V.448h4.354v15.648A2.09 2.09 0 0 0 6.59 18.32h8.707z"
    />
  </svg>
);
