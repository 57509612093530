import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23" viewBox="0 0 25 23">
    <path
      fill="#1D1D1B"
      fillRule="nonzero"
      d="M.351 22.248L9.275.448h6.534l8.955 21.8h-4.737l-1.781-4.36H6.75l-1.781 4.36H.35zm16.281-8.288l-3.914-9.6h-.44l-3.914 9.6h8.268z"
    />
  </svg>
);
