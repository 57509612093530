import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { containerVariants } from './utils';
import ItemMenu from './ItemMenu';

const MobileMenu = ({ menuItems, isOpen, classes, currentPath, ...rest }) => (
  <motion.div
    className={classes.container}
    variants={containerVariants}
    animate={isOpen ? 'openMenu' : 'closeMenu'}
    initial="closeMenu"
  >
    {menuItems.map(({ name, to }, index) => (
      <ItemMenu
        key={to || index}
        name={name}
        to={to}
        disabled={to === currentPath || (to === '/augenmobil/news/' && currentPath.includes('/augenmobil/news/'))}
        {...rest}
      />
    ))}
  </motion.div>
);

MobileMenu.defaultProps = {
  isOpen: false,
};

MobileMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
  isOpen: PropTypes.bool,
  currentPath: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default MobileMenu;
