import { useState, useCallback, useEffect } from 'react';

export const useMobileDetect = () => {
  const [state, setState] = useState(typeof window !== 'undefined' && window.innerWidth < 800);

  const handleResize = useCallback((e) => {
    setState(e.target.innerWidth < 800);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return state;
};

export const useSizeApp = () => {
  const [state, setState] = useState({
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
  });

  const handleResize = useCallback((e) => {
    setState({
      height: e.target.innerHeight,
      width: e.target.innerWidth,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return state;
};
