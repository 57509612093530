export default ({ components }) => {
  const { height, background } = components.header;
  const { height: curtainHeight, maxWidth } = components.curtain;

  return {
    wrapper: {
      display: ({ currentPath }) => (currentPath !== '/' ? 'flex' : 'none'),
      justifyContent: 'center',
      position: 'fixed',
      top: curtainHeight,
      left: 0,
      right: 0,
      height,
      background,
      boxShadow: [0, 2, 23, 0, 'rgba(204, 204, 204, 0.5)'],
      zIndex: 90,
      '@media (max-width: 799px)': {
        display: () => 'none',
      },
    },
    container: {
      display: ({ currentPath }) => (currentPath !== '/' ? 'flex' : 'none'),
      top: curtainHeight,
      height,
      maxWidth,
      zIndex: 90,
      '@media (max-width: 799px)': {
        display: () => 'none',
      },
    },
    backButton: {
      display: ({ currentPath }) => (currentPath !== '/' ? 'block' : 'none'),
      position: 'absolute',
      left: 15,
      top: 14,
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      margin: 'auto 0',
      background: 'transparent',
      '@media (max-width: 799px)': {
        display: () => 'none',
      },
    },
  };
};
