import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#1D1D1B"
      fillRule="nonzero"
      d="M23.063 12.144a11.008 11.008 0 0 1-3.212 8.12 10.976 10.976 0 0 1-8.108 3.216 10.976 10.976 0 0 1-8.108-3.217 11.008 11.008 0 0 1-3.212-8.119A11 11 0 0 1 3.63 4.017 10.968 10.968 0 0 1 11.743.8a10.968 10.968 0 0 1 8.114 3.217 11 11 0 0 1 3.206 8.127zm-4.57 0c0-4.136-2.836-7.2-6.75-7.2s-6.75 3.056-6.75 7.2 2.836 7.2 6.75 7.2 6.75-3.056 6.75-7.2z"
    />
  </svg>
);
